<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-header>
        <i class="icon-user mr-1"></i>{{ formLabel }} <!-- b-form-checkbox v-if="participantId !== null" v-model="participant.active" id="formfield-participant-active" class="float-right">Active</b-form-checkbox -->
      </b-card-header>
      <b-card-body>
    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show" autocomplete="off">
    
      <b-form-row>
        <b-col cols="12" md="7">
          <b-form-group id="formgroup-participant-fullname"
                        label="Full Name:"
                        label-for="formfield-participant-fullname">
            <b-form-input id="formfield-participant-fullname" autocomplete="off"
                          type="text"
                          v-model="participant.fullName"
                          required
                          placeholder="Jenny Smith" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group id="formgroup-participant-gender"
                        label="Gender:"
                        label-for="formfield-participant-gender">
            <b-form-select id="formfield-participant-gender"
                          :options="participantGenderOptions"
                          required
                          v-model="participant.gender" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group id="formgroup-participant-dob"
                        label="Birthday:"
                        label-for="formfield-participant-dob">
            <v-date-picker 
              id="formfield-participant-dob"
              v-model="participant.dob"
              required
              :input-props="{
                class: 'form-control'
              }"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      
      <b-form-row>
        <b-col cols="12">
          <b-form-group id="formgroup-participant-notes"
                        label="Notes:"
                        label-for="formfield-participant-notes">
            <b-form-input id="formfield-participant-notes"
                          type="text"
                          v-model="participant.notes"
                          placeholder="(Optional) Any important information regarding the participant" />
          </b-form-group>
        </b-col>
      </b-form-row>
      
      <!-- RELATIONSHIPS -->
      <b-form-row v-for="(r, index) in relationships" class="mb-2" :key="index">
        <b-col cols="12" md="5" class="mb-2">
          <label class="sr-only" for="">User:</label>
          <vSelect id="formfield-user-participant-user"
            v-model="r.user" 
            label="fullName"
            placeholder="Select Member(s)" 
            :options="userOptions"
            :reduce="rel => rel.user"
            required
            />
        </b-col>
        <b-col cols="12" md="3" class="mb-2">
          <label class="sr-only" for="">Relationship:</label>
          <b-form-select id="formfield-user-participant-relationship"
                        :options="userRalationshipOptions"
                        value-field="@id"
                        text-field="label"
                        required
                        v-model="r.relationship" />
        </b-col>
        <b-col cols="12" md="2" class="mb-2">
          <label class="sr-only" for="">Primary:</label>
          <b-form-radio :id="'formfield-user-participant-relationship' + index" value=true v-model="r.primaryContact" name="relationships[][primatyContact]">Primary</b-form-radio>
        </b-col>
        <b-col cols="12" md="2">
	        <label class="sr-only" :for="'relationships' + index">Remove</label>
	        <button v-if="relationships.length > 1" :id="'relationships' + index" type="button" v-on:click="removeRelationship(index)" class="btn btn-block btn-danger">
	            Rem -
	        </button>
        </b-col>
      </b-form-row>
      
      <b-row class="mb-2">
          <b-col>
              <b-button type="button" v-on:click="addNewRelationship" block>
                  Add a Participant Relationship
              </b-button>
          </b-col>
      </b-row>
      <hr />
      <b-button type="reset" variant="danger"><i class="fa fa-refresh"></i> Reset</b-button>
      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-check"></i> Submit</b-button>
    </b-form>
    </b-card-body>
    </b-card>
    <!-- status -->
    <b-modal ref="userFormStatusModal" ok-only hide-header>
      <b-container fluid>
       {{ formStatus }}
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'participant-form',
  data () {
    return {
      show: true,
      user: {}, // only new forms
      participantId: null,
      participant: {
        businesses: [],
        fullName: null,
        gender: null,
        dob: null,
        notes: null,
      },
      relationships: [],
      relationshipTpl: {
        id: null,
        user: null,
        relationship: null,
        primaryContact: false
      },
      formStatus: null,
      participantGenderOptions: [
        { value: 'female', text: 'Female' },
        { value: 'male', text: 'Male' },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userList',
      'userRalationships'
    ]),
    userOptions () {
      let users = this.userList
      users.push({ '@id': null, fullName: 'Select a User' })
      return users
    },
    userRalationshipOptions () {
      let userRalationships = this.userRalationships
      userRalationships.push({ '@id': null, label: 'Select a Relationship' })
      return userRalationships
    },
    formLabel () {
      return (!this.participantId) ? 'Participant Form' : this.participant.fullName
    }
  },
  methods: {
    ...mapActions([
      'getUser',
      'getUsers',
      'getUserRelationships',
      'getParticipant',
      'getParticipantRelationships',
      'postParticipant',
      'putParticipant',
      'postParticipantRelationship',
      'putParticipantRelationship',
      'deleteParticipantRelationship'
    ]),
    addNewRelationship: function () {
      this.relationships.push(Vue.util.extend({}, this.relationshipTpl))
    },
    removeRelationship: function (i) {
      if (this.relationships[i].id !== null) {
        this.deleteParticipantRelationship(this.relationships[i].id).then(() => {
          Vue.delete(this.relationships, i)
        })
      } else {
        Vue.delete(this.relationships, i)
      }
    },
    loadParticipant (id) {
      this.getParticipant({
        id: id
      }).then(resp => {
        this.participantId = resp.id
        this.participant = {
          fullName: resp.fullName,
          gender: resp.gender,
          dob: new Date(resp.dob),
          notes: resp.notes,
          businesses: resp.businesses
        }
      })
      
      // load participant relationships
      this.getParticipantRelationships({
        participant: id
      }).then(resp => {
        for (let i in resp) {
          let relationship = {
            id: resp[i].id,
            user: resp[i].user,
            relationship: resp[i].relationship['@id'],
            primaryContact: resp[i].primaryContact
          }
          this.relationships.push(relationship)
        }
      })
    },
    saveParticipantRelationships () {
      for (let i in this.relationships) {
        let relationship = {...this.relationships[i]}
        relationship.user = relationship.user['@id']
        if (relationship.id !== null) {
          this.putParticipantRelationship(relationship)
        } else {
          delete this.relationships[i].id
          delete relationship.id
          relationship.participant = this.participant['@id']
          this.postParticipantRelationship(relationship).then(resp => {
            this.relationships[i].id= resp.id
          })
        }
      }
    },
    async onSubmit (evt) {
      if (this.participantId) {
        if (!this.participant.businesses.length && this.currentBusiness['@id']) {
          // associate new participants w/ this business
          this.participant.businesses.push(this.currentBusiness['@id'])
        }
        // add id to participant data
        this.participant.id = this.participantId
        // save participant data
        await this.putParticipant(this.participant).then(resp => {
          let newData = resp
          newData.dob = this.$moment(newData.dob).format('YYYY-MM-DD')
          this.participant = newData
          // save relationships
          return this.saveParticipantRelationships()
        })
        this.showSuccess({message: 'the participant has been updated'})
      } else {
        if (this.currentBusiness['@id']) {
          // associate new participants w/ this business
          this.participant.businesses.push(this.currentBusiness['@id'])
        }
        // save participant data
        await this.postParticipant(this.participant).then(resp => {
          let newData = resp
          newData.dob = this.$moment(newData.dob).format('YYYY-MM-DD')
          this.participant = newData
          this.participantId = this.participant.id
          // save relationships
          return this.saveParticipantRelationships()
        })
        this.showSuccess({message: 'the participant has been added'})
      }
      this.$router.push({ name: 'participantDetails', params: { pid: this.participantId } })
    },
    resetParticipantData () {
      /* Reset our form values */
      this.participantId = null
    },
    onReset (evt) {
      evt.preventDefault()
      this.resetParticipantData()
      /* Trick to reset/clear native browser form validation state */
      this.show = false
      this.$nextTick(() => { this.show = true })
    },
    doGetUsers () {
      let business = this.currentBusiness.id ? this.currentBusiness.id : participant.businesses[0].id 
      this.getUsers({
        roles: 'member',
        businesses: business
      })
    }
  },
  watch: {
    participantId () {
      this.doGetUsers()
    }
  },
  created () {
    this.doGetUsers()
    if (this.$route.params.uid) {
      // new participant
      // set default member relationship
      this.getUser({
        id: this.$route.params.uid
      }).then(resp => {
        this.user = resp
        this.relationships.push({ id: null, user: resp, relationship: null, primaryContact: true })
      })
    }
    if (this.$route.query.pid) {
      // load participant data
      this.loadParticipant(this.$route.query.pid)
    }
    this.getUserRelationships()
  },
  components: { vSelect }
}
</script>
